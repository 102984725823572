'use client';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';

export interface VideoProps {
  src: string;
  alt?: string;
  aspect?: 'video' | 'square' | 'imax' | 'crt';
  posterImage?: string;
  fullWidth?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  hideControls?: boolean;
  capQuality?: boolean;
  className?: string;
  videoClassName?: string;
  priority?: boolean;
}

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

export const Video: React.FunctionComponent<VideoProps> = ({
  alt,
  posterImage,
  src,
  fullWidth = false,
  autoplay = false,
  loop = false,
  hideControls = false,
  aspect,
  capQuality = false,
  className,
  videoClassName,
  priority,
}) => {
  const [videoStarted, setVideoStarted] = useState(false);

  if (!src) return null;

  const aspectClass = clsx({
    'aspect-video': aspect === 'video' || aspect === 'imax',
    'aspect-square': aspect === 'square',
    'aspect-crt': aspect === 'crt',
    'md:aspect-imax': aspect === 'imax',
  });

  const VideoClasses = clsx(
    'not-prose aspect-video size-full overflow-hidden object-cover',
    { 'w-full': fullWidth },
    aspectClass,
    className
  );

  const getPosterUrl = () => {
    if (!posterImage) return;
    const url = new URL(posterImage);
    url.searchParams.append('q', '80');
    url.searchParams.append('fm', 'webp');
    url.searchParams.append('w', '1240');
    url.searchParams.append('h', '800');
    return url.toString();
  };

  const posterURL = getPosterUrl();

  return (
    <div className={clsx('VideoContainer', VideoClasses)} key={src}>
      <div className={clsx('size-full', !videoStarted && 'rounded-xl')}>
        <ReactPlayer
          playsinline
          url={src}
          loop={loop || autoplay}
          muted={autoplay}
          playing={autoplay}
          controls={!hideControls}
          className={clsx(
            videoClassName,
            'relative z-10 aspect-video size-full object-cover'
          )}
          width="100%"
          height="100%"
          onStart={() => setVideoStarted(true)}
          aria-label={alt}
          volume={0}
          config={{
            file: {
              hlsOptions: {
                capLevelToPlayerSize: capQuality,
              },
              hlsVersion: '1.5.8',
            },
          }}
        />
        {posterImage && (
          <img
            src={posterURL}
            className="absolute inset-0 h-full w-full object-cover"
            alt={alt}
          />
        )}
      </div>
    </div>
  );
};
export default Video;
