'use client';

import Script from 'next/script';

export interface AIAssistantProps {
  type?: 'popup' | 'embed';
  slug?: string;
}

export const AIAssistant: React.FC<AIAssistantProps> = ({
  type = 'popup',
  slug,
}) => {
  if (type === 'popup') {
    return (
      <>
        <Script
          src="https://www.chatbase.co/embed.min.js?chatbotId=y9fmWHw2Z-_wqKXRPEiE9"
          strategy="afterInteractive"
          onReady={() => {
            if (window) {
              window.embeddedChatbotConfig = {
                chatbotId: 'y9fmWHw2Z-_wqKXRPEiE9',
                domain: 'www.chatbase.co',
              };
            }
          }}
        />
      </>
    );
  } else if (type === 'embed') {
    return (
      <iframe
        src="https://www.chatbase.co/chatbot-iframe/y9fmWHw2Z-_wqKXRPEiE9"
        title="Sunshine Coast Guide"
        className="h-[540px] w-full rounded-md border border-brand-mothernature md:h-[620px]"></iframe>
    );
  }
  return null;
};

export default AIAssistant;
