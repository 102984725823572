import CreateCard from '@helpers/CreateCard';
import { InfiniteHits, UseHitsProps } from 'react-instantsearch';

export default function Hits(props: UseHitsProps) {
  const hit = ({ hit, sendEvent }) => (
    <span
      key={`search-${hit.objectID}`}
      onClick={() => sendEvent('conversion', hit, 'Search Result Clicked')}>
      {CreateCard({ item: hit })}
    </span>
  );
  return (
    <InfiniteHits
      hitComponent={hit}
      showPrevious={false}
      classNames={{
        root: 'flex flex-col justify-items-center',
        list: 'grid py-8 not-prose grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-2 gap-y-4',
        loadMore:
          'flex justify-self-center border border-brand-mothernature bg-white shadow-sm hover:bg-brand-mothernature nice-transition hover:text-white font-semibold py-2 px-4 rounded-lg w-auto mx-auto text-brand-mothernature',
        disabledLoadMore: 'hidden',
      }}
    />
  );
}
